@layer base {
    html{
        @apply scroll-smooth;
    }

    body {
        @apply m-0 p-0 box-border font-hind overflow-x-hidden;
    }
}

@layer utilities {
    [x-cloak] {
        display: none !important;
    }

    .rotate-y-180 {
        transform: rotateY(180deg);
    }

    .prevent-load-transition {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -ms-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
    }
}
