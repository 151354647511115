.world-map-partial {
    .point {
        @apply transition-all duration-500 ease-in-out;

        transition-property: background-color, box-shadow;

        box-shadow:
            0 0 0 0px theme("colors.primary.500"),
            0 0 0 0px theme("colors.white");

        &.active {
            @apply bg-white z-10;

            box-shadow:
                0 0 0 2px theme("colors.primary.500"),
                0 0 0 3px theme("colors.white");

            transform-style: preserve-3d;

            &::after,
            &::before {
                content: "";
                animation: grow 2s ease-out infinite;
                @apply absolute rounded-full pointer-events-none left-1/2 opacity-0 top-1/2 origin-center w-2 h-2 lg:w-4 lg:h-4 bg-primary-500;
            }

            &::before{
                animation-delay: 1s;
            }

            @keyframes grow {
                from {
                    transform: translateZ(-1px) translateX(-50%) translateY(-50%);
                    opacity: 0.8;
                }
                to {
                    transform: translateZ(-1px) translateX(-50%) translateY(-50%) scale(10);
                    opacity: 0;
                }
            }
        }
    }
}
