// Prevent layout shifts
.swiper {
    &:not(.swiper-initialized) {
        .swiper-slide {
            &:not(:first-child) {
                @apply hidden;
            }
        }
    }
}

.swiper-pagination-bullets {
    .swiper-pagination-bullet {
        @apply h-1 w-4 rounded-full bg-gray-100 transition-colors duration-500 ease-in-out hover:bg-primary-500;

        opacity: 1 !important;
        margin: 0 !important;

        &-active {
            @apply bg-primary-500;
        }
    }
}

.swiper-pagination {
    width: fit-content !important;
}

.swiper-pagination-fraction{
    --swiper-pagination-fraction-color: theme('colors.gray.50');

    .swiper-pagination-current{
        @apply text-primary-400;
    }
}

.swiper-nav-item {
    @apply transition-all duration-500 ease-in-out origin-center;

    &.swiper-button-disabled {
        @apply text-gray-300 dark:text-gray-700 cursor-default;
    }

    &:not(.swiper-button-disabled):hover{
        @apply scale-90;
    }
}