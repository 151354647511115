.wysiwyg{
    strong{
        @apply font-bold;
    }

    em{
        @apply italic;
    }

    ul{
        @apply list-disc pl-4 my-2;
    }

    ol{
        @apply list-decimal pl-4 my-2;
    }

    a{
        @apply text-primary-500 underline underline-offset-2;
    }
}