.hover-caret {
    svg {
        @apply ml-1.5 transition-all duration-500 ease-in-out transform text-current will-change-transform;
    }

    &:hover {
        svg {
            @apply translate-x-1;
        }
    }
}

.hover-scale{
    @apply origin-center hover:scale-90 duration-500 ease-in-out;
}

.hover-border{
    @apply relative isolate transition-colors duration-250 ease-in-out;

    &::after{
        content: "";

        @apply absolute left-0 top-0 w-full h-full -z-10 bg-primary-300 origin-bottom transition-transform duration-250 ease-in-out scale-y-0
    }

    &:hover{
        @apply text-white;

        &::after{
            @apply scale-y-100 origin-top;
        }
    }
}