.Form{
    .terms{
        label{
            @apply font-normal text-gray-800 dark:text-gray-100 text-base;
        }
    }

    textarea{
        min-height: 110px;

        @media (min-width: theme("screens.md")) {
            min-height: 190px;
        }
    }
}