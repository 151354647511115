@tailwind base;
@tailwind components;
@tailwind utilities;

@import "admin";
@import "base";
@import "buttons";
@import "effects";
@import "loading-animation";
@import "import-fonts";
@import "svg";
@import "links";
@import "components";
@import "swiper";
@import "lightbox";
@import "world-map";
@import "wysiwyg";
@import "plyr";
@import "form";
@import "gdpr-consent";
@import "link-fill";
