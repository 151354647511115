.WorldMap {
    .swiper-slide {
        @apply transition-colors;

        &.swiper-slide-active {
            @apply text-primary-500;
        }
    }

    .locations{
        &::before,
        &::after{
            content: "";
            @apply absolute z-10 left-0 top-0 w-full h-1/2 bg-gradient-to-b from-white to-white/0 pointer-events-none;
        }

        &::after{
            content: "";
            @apply bottom-0 top-auto bg-gradient-to-t;
        }
    }
}
