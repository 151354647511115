.underline-link{
    @apply relative w-fit;

    &::after{
        content: "";

        @apply absolute left-0 right-0 bottom-0 h-0.5 bg-current;
    }
}

.underline-link:not(.flex){
    @apply block;
}