.Navigation {
    .product-menu {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
        transition-property: clip-path;
        z-index: 9999;
        pointer-events: none;
        
        @apply duration-500 ease-in-out;
        
        &.open {
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            pointer-events: all;
        }
    }
}
