$duration: 500;
$stagger: 200;

@for $i from 1 through 5 {
    .loading-animation-#{$i} {
      animation: fadeIn #{$duration}ms ease-out both;
      animation-delay: #{$stagger * ($i - 1)}ms;
    }
  }

@keyframes fadeIn {
    from{
        transform: translateY(20px);
        opacity: 0;
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}