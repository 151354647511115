.ReferenceGrid{
    @media (min-width: theme("screens.md")) {
        .grid-container{
            @apply grid grid-cols-3 grid-rows-2 gap-2;

            a:nth-of-type(1){
                grid-row: 1 / 2;
                grid-column: 1 / 2;
            }

            a:nth-of-type(2){
                grid-row: 2 / 3;
                grid-column: 1 / 2;
            }

            a:nth-of-type(3){
                grid-row: 1 / 3;
                grid-column: 2 / 4;
            }
        }
    }
}