input[type="checkbox"]{
    @apply scale-150 ml-1 accent-primary-600;
}

input:focus-visible,
textarea:focus-visible{
    outline: theme('colors.primary.600') auto 1px !important;
}

label a{
    @apply text-primary-500 underline underline-offset-2 transition-colors hover:text-primary-700 ease-in-out duration-250;
}