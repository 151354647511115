:root {
  --cc-primary-color: theme("colors.primary.500");
  --cc-secondary-color: transparent;
  --cc-text-color: theme("colors.gray.100");
  --cc-text-color-headline: theme("colors.gray.100");
  --cc-modal-box-background: theme("colors.gray.900"); // background of content
  --cc-border-radius: theme("borderRadius.md");
  --cc-border-color: theme("colors.gray.800");
  --cc-switch-bg-inactive: theme("colors.gray.700");
  --cc-background: rgba(21, 34, 41, 0.85); // background for modal, body-before-element
  --cc-btn-primary-hover-bg: theme("colors.primary.600");
  --cc-btn-secondary-hover-bg: theme("colors.gray.800");
  --cc-btn-default-hover-bg: theme("colors.gray.800");
}

@import "@AccWebsiteBundle/GdprConsent/GdprConsent";

.consent-wrapper {
  @media (max-width: 768px) {
    left: 50% !important;
    top: 70px !important;
    transform: translateX(-50%) !important;
    bottom: 70px !important;
  }

  .script-actions-btn .is-secondary{
    margin: 0 !important;
  }

  .Headline{
    @apply text-3xl;
  }
}

.GdprConsentBrick {
  @apply text-gray-100;
}

.gdpr {
  &-element {
    @apply w-full h-full;
  }

  &-wrap {
    @apply w-full h-full;
  }

  &-text {
    @apply w-full h-full justify-center flex-col items-center text-center bg-gray-900 lg:hover:bg-gray-800 lg:border lg:border-primary-300/0 lg:hover:border-primary-300/100 lg:transition-colors lg:duration-500 rounded-md px-6 md:px-8 lg:px-10 pt-8 md:pt-10 pb-10 md:pb-12;
    display: flex !important;

    .title {
      @apply text-lg lg:text-xl font-semibold text-white;
    }

    .description {
      @apply py-4 md:py-6 lg:py-8;
    }

    .button {
      @apply inline-flex items-center justify-start uppercase font-semibold rounded-sm bg-primary-300 border-2 border-primary-300 text-white px-2 py-3;
    }
  }
}