@layer components {
    /*
    * Primary Button
    */
    .btn-primary-xl {
        @apply pt-4.5 px-5 pb-3.5 bg-primary-500 text-xl-button text-white rounded-sm;
    }

    .btn-primary-lg {
        @apply pt-4.5 px-5 pb-3.5 bg-primary-500 text-lg-button font-medium text-white rounded-sm;
    }

    .btn-primary-md {
        @apply pt-4 px-4 pb-3 bg-primary-500 text-md-button font-medium text-white rounded-sm;
    }

    .btn-primary-sm {
        @apply pt-3.5 px-3.5 pb-2.5 bg-primary-500 text-sm-button font-medium text-white rounded-sm;
    }

    /*
    * Secondary Button
    */

    .btn-secondary-xl {
        @apply pt-4 px-5 pb-3 text-xl-button text-primary-500 border-y-2 border-primary-500;
    }

    .btn-secondary-lg {
        @apply pt-4 px-5 pb-3 text-lg-button font-medium text-primary-500 border-y-2 border-primary-500;
    }

    .btn-secondary-md {
        @apply pt-3.5 px-4 pb-2.5 text-md-button font-medium text-primary-500 border-y-2 border-primary-500;
    }

    .btn-secondary-sm {
        @apply pt-3 px-3.5 pb-2 text-sm-button font-medium text-primary-500 border-y-2 border-primary-500;
    }

    /*
    * Secondary Button White
    */

    .btn-secondary-xl-white {
        @apply pt-4 px-5 pb-3 text-xl-button text-white border-y-2 border-white;
    }

    .btn-secondary-lg-white {
        @apply pt-4 px-5 pb-3 text-lg-button font-medium text-white border-y-2 border-white;
    }

    .btn-secondary-md-white {
        @apply pt-3.5 px-4 pb-2.5 text-md-button font-medium text-white border-y-2 border-white;
    }

    .btn-secondary-sm-white {
        @apply pt-3 px-3.5 pb-2 text-sm-button font-medium text-white border-y-2 border-white;
    }

    /*
    * Secondary Button On Dark
    */

    .btn-secondary-xl-on-dark {
        @apply pt-4 px-5 pb-3 text-xl-button text-primary-300 border-y-2 border-primary-300;
    }

    .btn-secondary-lg-on-dark {
        @apply pt-4 px-5 pb-3 text-lg-button font-medium text-primary-300 border-y-2 border-primary-300;
    }

    .btn-secondary-md-on-dark {
        @apply pt-3.5 px-4 pb-2.5 text-md-button font-medium text-primary-300 border-y-2 border-primary-300;
    }

    .btn-secondary-sm-on-dark {
        @apply pt-3 px-3.5 pb-2 text-sm-button font-medium text-primary-300 border-y-2 border-primary-300;
    }

    /*
    * Tertiary Button
    */

    .btn-tertiary-xl {
        @apply pt-1 pb-1.5 text-xl-button text-primary-500 border-b-2 border-primary-500;
    }

    .btn-tertiary-lg {
        @apply pt-1 pb-1.5 text-lg-button font-medium text-primary-500 border-b-2 border-primary-500;
    }

    .btn-tertiary-md {
        @apply pt-0.75 pb-1 text-md-button font-medium text-primary-500 border-b-2 border-primary-500;
    }

    .btn-tertiary-sm {
        @apply pt-0.5 pb-0.75 text-sm-button font-medium text-primary-500 border-b border-primary-500;
    }

    /*
    * Tertiary Button White
    */

    .btn-tertiary-xl-white {
        @apply pt-1 pb-1.5 text-xl-button text-white border-b-2 border-white;
    }

    .btn-tertiary-lg-white {
        @apply pt-1 pb-1.5 text-lg-button font-medium text-white border-b-2 border-white;
    }

    .btn-tertiary-md-white {
        @apply pt-0.75 pb-1 text-md-button font-medium text-white border-b-2 border-white;
    }

    .btn-tertiary-sm-white {
        @apply pt-0.5 pb-0.75 text-sm-button font-medium text-white border-b border-white;
    }

    /*
    * Tertiary Button On Dark
    */

    .btn-tertiary-xl-on-dark {
        @apply pt-1 pb-1.5 text-xl-button text-primary-300 border-b-2 border-primary-300;
    }

    .btn-tertiary-lg-on-dark {
        @apply pt-1 pb-1.5 text-lg-button font-medium text-primary-300 border-b-2 border-primary-300;
    }

    .btn-tertiary-md-on-dark {
        @apply pt-0.75 pb-1 text-md-button font-medium text-primary-300 border-b-2 border-primary-300;
    }

    .btn-tertiary-sm-on-dark {
        @apply pt-0.5 pb-0.75 text-sm-button font-medium text-primary-300 border-b border-primary-300;
    }
}
